import template from "./contact.html";

import * as firebase from "firebase/app";
import "firebase/functions";
import Vue from "vue";
import Component from "vue-class-component";

import Footer from "../../components/footer/Footer";
import NavigationBar from "../../components/navigationBar/NavigationBar";

interface ContactPageParams {
    fullName: string;
    email: string;
    message: string;
    phoneNumber?: string;
}

/** Regex to validate email address */
const EMAIL_REGEX: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

@Component({
    template,
    components: {
        NavigationBar,
        Footer
    }
})
export default class ContactController extends Vue
{
    // Varaibles
    // ============================================

    // Fields

    /** [Bound] The full name field value */
    private fullName: string = "";

    /** [Bound] The email field value */
    private email: string = ""

    /** [Bound] The phone number field value */
    private phoneNumber: string = "";

    /** [Bound] The message field value */
    private message: string = "";

    // Errors

    /** The full name field error message if any */
    private fullNameError: string | null = null;

    /** The email field error message if any */
    private emailError: string | null = null;

    /** The message field error message if any */
    private messageError: string | null = null;

    /** The error message for saving if any */
    private saveError: string | null = null;

    // State

    /** Whether or not the page is submitting a response */
    private isSubmitting: boolean = false;

    // Logic
    // ============================================

    /**
     * Validate fields before sending to server
     */
    private validate(): boolean
    {
        let isValid: boolean = true;
        if (!this.fullName.trim())
        {
            this.fullNameError = "Please enter your full name."
            isValid = false;
        }

        if (!this.email)
        {
            this.emailError = "Please enter an email address."
            isValid = false;
        }

        if (this.email && !EMAIL_REGEX.test(this.email))
        {
            this.emailError = "Please enter a valid email address."
            isValid = false;
        }

        if (!this.message)
        {
            this.messageError = "Please enter your message or question"
            isValid = false;
        }

        return isValid;
    }

    /**
     * Reset fields back to defaults
     */
    private reset(): void
    {
        this.fullName = "";
        this.email = "";
        this.phoneNumber = "";
        this.message = "";
    }

    // DOM Events
    // ============================================

    /**
     * DOM Click:
     * Called when the "Submit" button is clicked.
     * Validate fields and submit to server.
     */
    private async onSubmitClick(): Promise<void>
    {
        if (!this.validate())
        {
            return;
        }

        try
        {
            const params: ContactPageParams = {
                fullName: this.fullName,
                email: this.email,
                message: this.message
            }

            if (this.phoneNumber) { params.phoneNumber = this.phoneNumber; }

            this.isSubmitting = true;
            await firebase.functions().httpsCallable("SendContactEmail")(params);
            this.reset();
        }
        catch (err)
        {
            if (err)
            {
                this.saveError = err.message ? err.message : err;
            }
            else
            {
                this.saveError = "An unknown error occurred. Please try again"
            }
        }
        finally
        {
            this.isSubmitting = false;
        }
    }
}